import { register } from 'register-service-worker';
<template>
  <v-container fluid>
    <v-layout align-center justify-center fill-height>
      <v-card class="elevation-12 mx-auto pb-8" width="548">
        <nav class="d-flex justify-space-around">
          <router-link
            to="login"
            class="nav-tab w-50 py-4 text-center login-tab"
            active-class="active-tab"
            >{{ $t(`Login`) }}</router-link
          >

          <router-link
            to="registration"
            class="nav-tab w-50 py-4 text-center registration-tab"
            active-class="active-tab"
            >{{ $t(`Registration`) }}</router-link
          >
        </nav>
        <router-view />
      </v-card>
    </v-layout>
  </v-container>
</template>
<script>
export default {};
</script>

<style lang="scss">
.shadow {
  -webkit-box-shadow: 0px -10px 25px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px -10px 25px -5px rgba(0, 0, 0, 0.08);
  box-shadow: 0px -10px 25px -5px rgba(0, 0, 0, 0.08);
}

.nav-tab {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-size: 1.375rem;
  color: #000000;
  text-decoration: none;

  &.active-tab {
    z-index: 1;
    color: #005fce;
    background-color: #fff;

    &.login-tab {
      -webkit-box-shadow: 10px 0px 5px -5px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 10px 0px 5px -5px rgba(0, 0, 0, 0.08);
      box-shadow: 10px 0px 5px -5px rgba(0, 0, 0, 0.08);
    }

    &.registration-tab {
      -webkit-box-shadow: -10px 0px 5px -5px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: -10px 0px 5px -5px rgba(0, 0, 0, 0.08);
      box-shadow: -10px 0px 5px -5px rgba(0, 0, 0, 0.08);
    }
  }
}

.inset-shadow {
  -webkit-box-shadow: inset 0px -10px 10px -4px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0px -10px 10px -4px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0px -10px 10px -4px rgba(0, 0, 0, 0.08);
}
</style>
